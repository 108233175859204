/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */
/* @font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */
*,::before,::after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;outline: none;}
body{overflow:hidden;overflow-y:auto;font-family: 'latoregular', sans-serif !important;font-size: 14px;}
html{scroll-behavior: smooth}
body,ul,ol,li,h1,h2,h3,h4,h5,h6,figure,p,strong{padding:0;margin:0;list-style:none;}
body,p{font-size:14px;color:#666;line-height: 20px;}
h1,h2,h3,h4,h5,h6{font-weight: normal;}
a,.btn,button,input,select{text-decoration:none;outline:none;border: none;}
img:not([src]){opacity:0}
select{cursor:pointer;background: transparent;}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
input[type=number] {-moz-appearance: textfield;}
::-webkit-scrollbar {width: 5px;}
::-webkit-scrollbar-track {background: #ffff; }
::-webkit-scrollbar-thumb {background: #ebebeb;border-radius: 2px;}
.img-responsive {max-width: 100%;height: auto;max-height: 100%;}
.container{padding:0 15px;margin:0 auto;width: 100%; max-width:1170px;}
.container-fluid{padding:0 15px;margin:0 auto;width:100%}
.containerFull {width: 100%; max-width: 1600px;margin: 0px auto;}
.container1350{ padding: 0 15px; margin: 0 auto; width: 100%; max-width: 1350px;}
figure img{vertical-align: middle;}
.imgFluid{max-width:100%;height:auto;max-height:100%}
.overflow-hidden{overflow: hidden;}
.textCenter{text-align: center;}
.dispBlok{display: block;}
a{color: inherit;cursor: pointer;} 
.font70{font-size: 70px;}
.font50{font-size: 50px;}
.font46{font-size: 46px;}
.font40{font-size: 40px;}
.font36{font-size: 36px;}
.font30{font-size: 30px;}
.font28{font-size: 28px;}
.font26{font-size: 26px;}
.font24{font-size: 24px;}
.font22{font-size: 22px;}
.font21{font-size: 21px;}
.font20{font-size: 20px;}
.font18{font-size: 18px;}
.font16{font-size: 16px;}
.font14{font-size: 14px;}
.font12{font-size: 12px;}
.font10{font-size: 10px;}
.font8{font-size: 8px;}
.text666{color: #666;}
.text222{color: #222;}
.text333{color: #333;}
.textfff{color: #fff;}
.text000{color: #000;}
.textpink{color: #f87690;}
.textblue{color: #1c2f59;}
.textred{color: #f16565;}
.disabled{filter: grayscale(1);}
.textLightGrey{color: #9f9f9f !important;}
.textLightBlue{color: #734ea4 !important; }
.backGroundGrey{background: #f7fafc;}
.bgLigtgray{background: #fafafa;}
.textUpper{text-transform: uppercase;}
.textCaptlise{text-transform: capitalize;}
.textItalic{font-style: italic;}
.bgPink{background: #eb5976;}
.bgWhite{background: #fff;}
.w100{width: 100%;}
.lineheightNom{line-height: normal;}
.lineheight{line-height: 1.5;}
.lineHeight10{line-height: 10px;}
.lineHeight14{line-height: 14px;}
.lineHeight16{line-height: 16px;}
.lineHeight20{line-height: 20px;}
.lineHeight24{line-height: 24px;}
.lineHeight26{line-height: 26px;}
.lineHeight36{line-height: 36px;}
.lineHeight32{line-height: 32px;}
.lineHeight30{line-height: 30px;}
.lineHeight34{line-height: 34px;}
.lineHeight40{line-height: 40px;}
.lineHeight46{line-height: 46px;}
.lineHeight56{line-height: 56px;}
.lineHeight60{line-height: 60px;}
.f500{font-weight: 500;}
.fthin{font-weight: 100;}
.f600{font-weight: 600;}
.f700{font-weight: 700;}
.f900{font-weight: 900;}
.fbold{font-weight: bold;}
.plr{padding-left: 30px;padding-right: 30px;}
.pb16{padding-bottom: 16px;}
.pb24{padding-bottom: 24px;}
.pb30{padding-bottom: 30px;}
.pb80{padding-bottom: 80px;}
.pt16{padding-top: 16px;}
.pt50{padding-top: 50px;}
.mAuto{margin: 0 auto;}
.mt5{margin-top: 5px;}
.mt16{margin-top: 16px;}
.mt10{margin-top: 10px;}
.mt12{margin-top: 12px;}
.mt20{margin-top: 20px;}
.mt24{margin-top: 24px;}
.mt25{margin-top: 25px;}
.mt46{margin-top: 46px;}
.mb36{margin-bottom: 36px}
.mb40{margin-bottom: 40px}
.mb46{margin-bottom: 46px}
.mb30{margin-bottom: 30px}
.mb20{margin-bottom: 20px}
.mb24{margin-bottom: 24px}
.mb16{margin-bottom: 16px}
.mb12{margin-bottom: 12px}
.mb10{margin-bottom: 10px}
.posR{position: relative;}
.mxAuto{margin: 0 auto;}
.bgGray{background: #f8f8f8;}
.bgf9f9{background: #f9f9f9;}
.bgWhite{background: #fff;}
.cbcbcb{background: #cbcbcb;}
.userSelectNone{user-select: none;}
.w100{width: 100%;}
.width100{width: 100% !important;}
.textRed{color:  #FC5454 !important;}
.textGreen{color:#569A1E !important;}
/* .bodyMainActive{position: relative;z-index: 9;} */
.overlay{background-color:rgba(0,0,0,.7);height:100%;width:100%;position:fixed;left:0;right:0;top:0;bottom:0;z-index:9;}
.formLable{color: #666;font-size: 12px;position: absolute;left: 16px;top: 7px;background-color: #fff;transition: 0.3s;z-index: 0;}
.formItem{margin-bottom: 24px; position: relative;transition: 0.3s;}
.formItem input{transition: 0.3s;z-index: 2;}
.formItem input:focus + .formLable,.formItem input:valid + .formLable{padding: 0px 7px;top: -10px;left:12px;font-size: 12px;transition: 0.3s;color: #222;z-index: 3;}
.btnDisabled{background-color: #cbcbcb !important;cursor: no-drop !important;}
.textEllpise{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%;}
.lineClamp{display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;}
.dIlineBlock{display: inline-block;}
.displayBlock{display: block;}
.dBCenter{display: block !important;text-align: center !important;width: 100% !important;}
.form-group{margin-bottom: 15px;}
.form-control{padding:6px 0px;display:block;width:100%;height:33px;font-size:14px;color:#222;background:#fff;border: none; border-bottom:1px solid #666;box-shadow:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none}
.formControlBorder{padding:15px;display:block;width:100%;font-size:14px;color:#222;background:#fff;border: none;border-radius: 5px; border:1px solid #cbcbcb;box-shadow:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none}
.formControltransparent{padding:15px;display:block;width:100%;font-size:14px;color:#222;background:#fff;border: none;border: transparent; box-shadow:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none}
.inputBoxBorder{border-radius: 5px; border:1px solid #cbcbcb;padding: 0 15px;}
.inputTag{position: absolute;right: 16px;font-size: 12px;color: #000;top: 13px;}
.inputInline{display: flex;grid-gap: 24px;}
.inputInline .formItem{flex: 1}
.form-group{margin-bottom: 15px;}
.btnBgBlue{background: #1c2f59;color: #fff;font-size: 16px;}
.btnBgWhite { border: 0.5px solid #EB5976 !important; color: #EB5976; background: #FFF; font-size: 16px;}
.formControl{padding: 8px 16px;display:block;position: relative;width:100%;height:35px;font-size:12px;color:#222;background:#fff;border: none; border:0.5px solid #cbcbcb;box-shadow:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:none;border-radius: 5px;}
.btn{padding:15px;display:flex;text-align:center;align-items: center;grid-gap: 0 10px;justify-content: center; cursor:pointer;border:none;outline: none; line-height:20px;border-radius:5px;transition:.5s}
.btnBlock{width: 100%;text-align: center;justify-content: center;}
.btnPrimery{background: #eb5976;color: #fff;font-size: 16px;}
.btnCenter{width: fit-content;margin: auto;}
.borderBottom{border-bottom:solid 1px rgba(248, 118, 114, 0.5)}
select,select option {color: #000000;}
select:invalid,select option[value=""]{color: #D7D7D7;}
select option:first-child {color: #D7D7D7;}
.dropDown{position: relative;}
.dropDown::after{content: '';pointer-events: none;position: absolute;right: 15px;top: 15px;background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23222222' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E") no-repeat;width: 12px;height: 7px;}
.overlayLoader{display: flex;width: 100%;height: 100%;align-items: center;justify-content: center; top: 0;position: absolute;background-color: rgb(255 255 255 / 70%);z-index: 11;}
.overlayLoader img{width:40px}
.posF{position: fixed !important;}
.mobileInput{display: flex;padding: 0;}
.mobileInput input{padding-left: 15px; border-radius: 0px 5px 5px 0px;border-left: none !important;}
.mobileInput .formLable{left: 102px;}
.countryCode{padding: 10px 30px 10px 10px;width: 85px;display: flex;align-items: center;border-radius: 5px 0px 0px 5px;}
.countryCode2{padding: 10px 25px 10px 10px;width: 125px;display: flex;align-items: center;border-radius: 5px 0px 0px 5px;}
.countryCode.dropDown::after{right: 10px;}
.countryCode em{margin-right: 5px;width: 11px;}
.link{color: #eb5976;cursor: pointer;}
.btnSecondery{background: #fff;color: #000000;}
.knowBtn{display: flex;align-items: center;background: transparent;color: #222222;grid-gap: 10px;cursor: pointer;transition: 0.3s;}
.knowBtn:hover{color: #eb5976;transition: 0.3s;}
.knowBtn span{display: flex;align-items: center;stroke: #222;}
.knowBtn:hover svg{stroke:#eb5976;transition: 0.3s;}
.section{padding: 60px 0;}
.displayNone{display: none !important;}
.displayblock{display: block !important;}
.dFlex{display: flex;}
.dFspaceBetween{justify-content: space-between;}
.alignItem{align-items: flex-start;}
.displayGrid{display: grid;}
.grid2{grid-template-columns:repeat(2,1fr);}
.grid3{grid-template-columns:repeat(3,1fr);grid-gap: 45px;}
.grid4{grid-template-columns:repeat(4,1fr);}
.grid5{grid-template-columns:repeat(5,1fr);}
.grid6{grid-template-columns:repeat(6,1fr);}
.whiteSpace{white-space: nowrap !important;}
.gridGap24{grid-gap: 24px;}
.gridSpan2{grid-column: span 2;}
.primaryBtn{background-color: #eb5976;color: #fff;border-radius: 5px;cursor: pointer;}
.secondaryBtn{background-color:#1c2f59;color: #fff;border-radius: 5px;cursor: pointer;}
input[type=checkbox]{width: 18px;height: 18px;appearance: none;border: 1px solid #ccc;border-radius: 2px;cursor: pointer;}
input[type=checkbox]:checked{background-color: #eb5976;border: none;position: relative;}
input[type=checkbox]:checked::after{content: '';position: absolute;left: 5px;top: 6px;background: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.727 1-6 6L1 4.273' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E") no-repeat center;width: 9px;height: 6px;}
.breadcrum {padding: 26px 0px ;}
.breadcrum ul{white-space: nowrap;overflow: hidden;}
.breadcrum li{padding-left: 10px;display: inline-block;position: relative;line-height: normal;font-size: 12px;cursor: pointer;}
.breadcrum li a{white-space: nowrap;}
.breadcrum li:first-child{padding: 0;}
.breadcrum li::before{content: '';position: absolute;left: 5px;top: 3px;width: 1px;height: 70%;background-color: #666;}
.breadcrum li:first-child::before{display: none;}
.breadcrum li:last-child{color: #222;font-weight: 600;transition: 0.3s;cursor: default;}
.breadcrum li:last-child .breadcrumDetails{display: none;}
.breadcrum li:last-child.active::after{transform: rotate(180deg);transition: 0.3s;}
.breadcrum li:last-child.active .breadcrumDetails{display: block;}
.breadcrum .breadcrumDetails{padding: 10px 5px 0px;position: absolute;max-height: 220px;overflow: auto;background: #fff;box-shadow: 0 0 1px 0 #000;transition: .3s;z-index: 1;top: 27px;width: 200px;}
.breadcrum .breadcrumDetails label{padding-left: 10px;display: block;margin-bottom: 5px;font-weight: 600;color: #222;}
.breadcrum .breadcrumDetails li{padding: 7px 10px 10px 15px;white-space: nowrap;font-weight: normal;border-top: 1px solid #f2f2f2;cursor: pointer;width: 100%;}
.breadcrum .breadcrumDetails li a{color: #000;font-size: 14px;}
.breadcrum .breadcrumDetails li::before{display: none;}
.breadcrum .breadcrumDetails li:last-child::after{display: none;}
.designIdeaBreadcrum li:last-child{padding-right: 18px;}
.designIdeaBreadcrum li:last-child::after{content: '';position: absolute;right: 0;top: 6px;background-image: url(../images/arrow.png);width: 12px;height: 7px;}

.pagination{display: flex;align-items: center;justify-content: center;padding: 46px 0px 36px;}
.pagination button{cursor: pointer;border: none;background: #fff;height: 20px;}
.pagination button.next{transform: rotate(180deg);line-height: 20px;}
.pagination button img{width: 10px;}
.pagination ul{margin: 0px 12px;}
.pagination li{margin-left: 12px;display: inline-block;cursor: pointer;transition: 0.3s;font-weight: 600;}
.pagination li:first-child{margin: 0;}
.pagination li.active{color: #eb5976;}
.pagination li:hover{transition: 0.3s;color: #eb5976;}


.switchBtn{margin: 0px 0px 16px;display: flex;}
.switchBtn input{margin: 0;}
.switchBtn label{margin-left: 10px;cursor: pointer;font-size: 12px;display: flex !important;align-items: center; max-width: inherit !important;white-space: nowrap;}
.switchBtn label span{margin-left: 5px;margin-bottom: 0px;display: flex;align-items: center;color: #4caf50;}
.switchBtn label em{margin-right: 5px;position: relative;top: 2px;}
.error .formControl {border: 0.5px solid #ff9f9f}
.error .errorText,.errorText{position: absolute;bottom: -14px;left: 0;font-size: 10px;color: red;font-style: italic;line-height: initial;}
.otpErrorBx{margin-top: 24px;} 
.otpErrorBx .otpApiError{color: #eb1c1c;display: block;text-align: center;font-size: 10px;}


.apiError{position: absolute;color: #eb1c1c;text-align: center;font-size: 10px;line-height: initial;width: 100%;top: -19px;}
.termsOfusez{margin-top: 16px;position: relative;line-height: initial;}
.termsOfusez span{font-size: 11px;}
.ctaLeadForm{position: relative;text-align: left;}
.modularDesignCss h4{margin-bottom: 12px;font-size: 16px;text-align: center;}
.modularDesignCss .formControl{height: 35px;font-size: 12px;}
.modularDesignCss .formLable{left: 16px;top: 7px;font-size: 12px;}
.modularDesignCss .formItem input:focus + .formLable, .formItem input:valid + .formLable{font-size: 10px;}
.modularDesignCss .mobileInput .formLable{left: 100px;}
.modularDesignCss .dropDown::after{top: 14px;}
.modularDesignCss button{font-size: 14px;line-height: 35px !important;}

.leadForm {padding: 31px 22px 24px;border-radius: 10px;}
.leadForm .heading{margin-bottom: 31px;line-height: normal;}
.leadForm label{color: #222;}
.leadForm .selectNumber{display: flex;}
.leadForm .selectNumber .select{max-width: 60px;width: 100%;}
.leadForm button{padding: 0px 15px;width: 100%;line-height: 50px;}
.leadForm input::placeholder {color: #d7d7d7;opacity: 1;font-size: 12px;}
.leadForm input:-ms-input-placeholder { color: #d7d7d7;font-size: 12px;}
.leadForm input::-ms-input-placeholder {color: #d7d7d7;font-size: 12px;}
.leadForm .userDetails{display: none;}
.leadForm .select{position: relative;}
.leadForm .select::before{content: '';position: absolute;right: 10px;top: 13px;background: url(../images/arrow.png) no-repeat;width: 12px;height: 7px;}
.leadForm select{padding-right:15px ;}
.leadForm select option{padding: 0px 5px;}
.leadForm.nextLeadForm{padding: 15px;}
.leadForm .userDetails .heading{margin-bottom: 20px;font-size: 16px;}
.leadForm .active{display: block;}
.otpVerification{display: none;}
.otpVerification .heading{margin-bottom: 10px;font-size: 16px;}
.otpVerification figure{margin-bottom: 12px;}
.otpVerification .otpSentTImes{color: #000;}
.otpVerification p{color: #000;}
.otpVerification .changeNumber{display: flex;align-items: center;justify-content: space-between;}
.otpVerification .changeNumber label{font-weight: 700;}
.otpVerification .changeNumber span{color: #f87690;cursor: pointer;}
.otpVerification .otpPhoneTimeBx{display: block;position: relative;}
.otpVerification .otpPhoneTimeBx .time{position: absolute;right: 0;top: 10px;}
.otpVerification .otpPhoneTimeBx input{border-bottom: 1px solid #bdbdbd;}
.otpVerification .confirmOTP{text-align: center;line-height: 40px;}
.otpVerification .notReceveCode{margin-top: 20px;font-size: 12px;text-align: center;color: #000;}
.otpVerification .notReceveCode span{color: #f87690; cursor: pointer;}
.thankyouLeadForm{display: none;}
.thankyouLeadForm .heading{margin-bottom: 26px;font-size: 16px;text-align: center;}
.thankyouLeadForm figure{margin-bottom: 22px;text-align: center;}
.thankyouLeadForm p{text-align: center;font-size: 12px;line-height: 18px;font-weight: 600;}

.enquiryForm{flex: 1;display: flex;justify-content: center;align-items: center;padding: 16px;}
.formBox{border-radius: 10px;padding: 24px;}
.overLayCenter {display: flex; align-items: center; justify-content: center;}
.cursorPointer{cursor: pointer;}
.regFormBox{padding:20px;margin: 20px auto; border:solid 1px #cbcbcb;background:#fff;position:relative;max-width:420px;width: 100%;border-radius: 10px;}
.regFormBox .closeModal{color:#666;border:none;background: transparent;align-self: flex-start;cursor: pointer;z-index: 2;right: 16px;top: 20px;position: absolute;width: 24px;height: 24px;border:solid 0.5px #cbcbcb;display: flex;align-items: center;justify-content: center;border-radius: 5px;}
.regFormBox .closeBtn{position:absolute;width:14px;height:14px;top:14px;right:14px;color:#666;font-size:14px;align-items:center;justify-content:center;cursor:pointer}
.regFormBox .leadFormClose{position: relative;width: 100%;height: 100%;}
.regFormBox .leadFormClose::after{content: '';position: absolute;left: 4px;top: 11px;width: 14px;height: 1px;background: #505050;transform: rotate(45deg);}
.regFormBox .leadFormClose::before{content: '';position: absolute;left: 4px;top: 11px;width: 14px;height: 1px;background: #505050;transform: rotate(135deg);}

.regFormBox .switch{position:relative;display:inline-block;width:35px;height:20px}
.regFormBox .switch input{opacity:0;width:0;height:0}
.regFormBox .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s}
.regFormBox .slider:before{position:absolute;content:"";height:16px;width:16px;left:3px;bottom:2px;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.regFormBox input:checked + .slider{background-color:#eb5976}
.regFormBox input:focus + .slider{box-shadow:0 0 1px #eb5976}
.regFormBox input:checked + .slider:before{-webkit-transform:translateX(26px);-ms-transform:translateX(13px);transform:translateX(13px)}
.regFormBox .slider.round{border-radius:34px}
.regFormBox .slider.round:before{border-radius:50%}
.freeEstimate .regFormBox{max-width: 950px;}
.freeEstimate .estimateLeadF ul{margin-bottom: 20px;max-height: 420px;overflow-y: auto;}

@media screen and (max-width: 540px){
    .freeEstimate{padding: 10px;}
    .freeEstimate .regFormBox{max-width: 100%;width: 100%;padding: 15px;}
    .freeEstimate .container{padding: 0;}
    .freeEstimate .getEstimateBox{margin-top: 25px;}
    .freeEstimate .estimateLeadF{padding: 0;}
    .freeEstimate .termsOfusez span {font-size: 11px;display: flex;flex-wrap: wrap;line-height: 15px;}
}

.otpBox .mainForm{display: flex;flex-direction: column;align-items: center;justify-content: center;}
.otpBox .mainForm p .lonk{cursor: pointer;}
.otpBox .mainForm .textpink{cursor: pointer;}
.otpBox .mainForm .mwidth{max-width: 360px;display: block;white-space: break-spaces;}
.otpBox .mainForm form{display: flex;align-items: center;justify-content: space-between;}
.otpBox .mainForm button{width: 100%;line-height: 50px;}
.otpBox .mainForm form input{ margin-right: 15px;border-radius: 5px;border: solid 0.5px #cbcbcb;font-size: 16px;color: #000;height: 50px;width: 50px;text-align: center;}
.otpBox .mainForm form input:last-child{margin-right: 0px;}

.modelTitle{max-width: calc(100% - 40px);white-space: initial;}
.thanksBox p{margin: 0 auto 24px;max-width: 353px;}
.thanksBox button{ width: 100%;line-height: 50px;}
.countryCode select,.countryCode2 select{width: 100%;height: 100%;padding: 5px 10px; border:none;-webkit-appearance: none;-moz-appearance: none;appearance: none;background: none;}
.carousel{position: relative;}
.carousel__back-button{position: absolute;left: 0;top: 0;}
.carousel__next-button{position: absolute;right: 0;top: 0;transform: rotateY(180deg)}
.carousel__next-button[disabled],.carousel__back-button[disabled]{opacity: 0.5;}
.homeBannerCarousel .carousel__inner-slide{width: 100% !important;}
/* .carousel__inner-slide {width:calc(100% - 16px) !important;} */
/* .carousel__inner-slide a{display: block;} */
.centerCarousel .sliderTray___-vHFQ{margin: 0 auto !important;}
.noDataFoundImg{padding: 10px 0px;display: flex;align-items: center;justify-content: center;}
.noDataFoundImg .noDataFoundFig{display: flex;}

.arrow {border: solid black;border-width: 0 2px 2px 0;display: inline-block;padding: 3px;width: 10px;height: 10px;}
.right {transform: rotate(-45deg);-webkit-transform: rotate(-45deg);  }
.left {transform: rotate(135deg); -webkit-transform: rotate(135deg);}
.up {  transform: rotate(-135deg); -webkit-transform: rotate(-135deg);}
.down { transform: rotate(45deg);-webkit-transform: rotate(45deg); }
.arrowBox{background-color:hsla(0,0%,97%,.7); box-shadow: 0 2px 5px 0 rgb(166 166 166 / 25%); width: 40px; height: 40px; cursor: pointer; display: flex; border-radius: 5px; align-items: center; justify-content: center;}
.scrollBarHide::-webkit-scrollbar{display:none;height: 0px;}
.scrollBarHide{-webkit-scrollbar-width: none;-moz-scrollbar-width: none;-ms-scrollbar-width: none;scrollbar-width: none;}


.hoverZoom figure{overflow: hidden;}
.hoverZoom figure img{transition: .3s;transform: scale(1.01);}
.hoverZoom:hover figure img {transition: .3s;transform: scale(1.1);}
.autocompleteSearh{z-index: 1;background: #fff;padding: 10px;box-shadow: 0 2px 15px 0 rgba(225, 225, 225, 1);border-radius: 0px 0px 10px 10px;max-height: 200px;overflow: auto;position: absolute;}
.autocompleteSearh li{color: #000;padding-bottom: 10px;border-bottom: 0.5px solid #cbcbcb;margin-bottom: 10px;cursor: pointer;}
/* toast css */
.toastPosition{top: 8rem !important;width: auto !important;}
.customToast {min-height: auto !important;}
.pdLft{padding-left: 40px !important;}
.bdrBtmNone .dynamicborder{border-bottom: none !important;}
.before-head .dynamicborder{border-bottom: 1px dashed #9A1FE6;border-right: 1px solid transparent;}
.headNone .dynamicborder{border-bottom: none;}
.headNone .borderBottom{border-bottom: none;}
.mainContainer{padding:0 15px;margin:0 auto;width: 100%; max-width:1350px;}


@media screen and (max-width:1600px) {
	.containerFull{width: 100%;padding:0 24px;}
}
@media screen and (max-width:1280px) {
	.container{width: 100%;}
    .containerFull{padding:0 15px;}
}

@media screen and (max-width: 768px){
.btn{padding: 12px 15px;}
.textSMcenter{text-align: center;}
.fontSM26{font-size: 26px;}
.fontSM22{font-size: 22px;}
.fontSM20{font-size: 20px;}
.fontSM16{font-size: 16px;}
.fontSM14{font-size: 14px;}
.fontSM12{font-size: 12px;}
.fontSM10{font-size: 10px;}
.lineheightSM3{line-height: 1.36;}
.gridSM1{grid-template-columns:repeat(1,1fr)}
.gridSM2{grid-template-columns:repeat(2,1fr)}
.mbSM15{margin-bottom: 15px;}
.mbSM26{margin-bottom: 26px;}
.inputInline{flex-direction: column;grid-gap: 0;}
.flexDirectionSMColum{flex-direction: column;}
.dNoneSM {display: none;}
/* .carousel{padding: 0 33px;} */
}
model-viewer{height: inherit;width: inherit;}


@media screen and (max-width: 540px){
    body,p{font-size: 16px;}
.breadcrum{padding: 15px 0px;}
.breadcrum ul{max-width: 540px;display: flex;}
.breadcrum li{font-size: 12px;}
.breadcrum li:last-child{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.leadForm{padding: 15px;}
.textCenterMob{text-align: center;}
.regFormBox{width: calc(100% - 30px);}
.section{padding: 40px 0;}
/* .carousel__inner-slide {width: 100% !important;} */
.noDataFoundImg .noDataFoundFig{max-width: 200px;}
::-webkit-scrollbar{height: 0px;}
::-moz-scrollbar{height: 0px;}
.toastPosition{top:4rem !important;width: auto !important;left: calc(100% - 92%) !important;}
.customToast {min-height: auto !important;}
.secondaryBtn{cursor: default;}
}

@media screen and (max-width:360px) {
    .overlay{overflow: auto;}
}

/* custom slider arraow */
.scrollBehaviour{transition: 0.3s;scroll-behavior: smooth;}
